<template>
<ModalForm
    id="modalPersonEditForm"
    :title="titleModal"
    :validationSchema="validationSchema"
    :initialValues="initialValues"
    :fnCallbackSubmit="handleCrudAction"
    :readOnly="disableSubmitButton"
    ref="modalForm"
>
    <RowForm v-show="showCodeField">
        <label class="fs-6 fw-bold mb-2">Código</label>
        <Field  name="Code" v-slot="{value, field, errorMessage}">
            <el-form-item :error="errorMessage">
                <el-input
                    v-bind="field"
                    class="itemControl"
                    type="text" 
                    placeholder="Código"
                    :validate-event="false"
                    :disabled="true"
                    :model-value="value"
                ></el-input>
            </el-form-item>
        </Field>
    </RowForm>

    <!-- NOMBRE -->
    <div class="fv-row rowItemControl">
        <label class="required fs-6 fw-bold mb-2">Nombre</label>
        <Field  name="Name" v-slot="{value, field, errorMessage}">
            <el-form-item :error="errorMessage">
                <el-input
                    v-bind="field"
                    class=""
                    type="text" 
                    placeholder="Nombre"
                    :validate-event="false"
                    :disabled="readOnly"
                    :model-value="value"
                ></el-input>
            </el-form-item>
        </Field> 
    </div>  

    <!-- TIPO Y RFC -->
    <RowForm class="paddingTop" :twoColums="true">
        <template v-slot:default>
            <label class="required fs-6 fw-bold mb-2">Tipo</label>
            <Field name="PersonRoleType">
                <SelectOption 
                    class="itemControl"
                    name="PersonRoleType"
                    :options="PersonRoleType"
                    :disabled="readOnly"
                    @changeSelectValue="onChangeRoleType"
                />
            </Field>
        </template>
        <template v-slot:col2>
            <label class="fs-6 fw-bold mb-2">RFC</label>
            <Field  name="RFC" v-slot="{value, field, errorMessage}">
                <el-form-item :error="errorMessage">
                    <el-input
                        v-bind="field"
                        class="itemControl"
                        type="text" 
                        placeholder="RFC"
                        :validate-event="false"
                        :disabled="readOnly"
                        :model-value="value"
                        :maxlength="13"
                    ></el-input>
                </el-form-item>
            </Field>
        </template>
    </RowForm>
    
    <!-- EMAIL Y TELÉFONO -->
    <div class="inlineRows paddingTop">
        <div class="fv-row rowItemControl">
            <label class="fs-6 fw-bold mb-2">Email</label>
            <Field  name="Email" v-slot="{value, field, errorMessage}">
                <el-form-item :error="errorMessage">
                    <el-input
                        v-bind="field"
                        class="itemControl"
                        type="text" 
                        placeholder="Email"
                        :validate-event="false"
                        :disabled="readOnly"
                        :model-value="value"
                    ></el-input>
                </el-form-item>
            </Field>
        </div>
        <div class="fv-row rowItemControl padd">
            <label class="fs-6 fw-bold mb-2">Teléfono</label>
            <Field  name="PhoneNumber" v-slot="{value, field, errorMessage}">
                <el-form-item :error="errorMessage">
                    <el-input
                        v-bind="field"
                        class="itemControlMiddle"
                        type="text" 
                        maxlength="10"
                        placeholder="Teléfono"
                        :validate-event="false"
                        :disabled="readOnly"
                        :model-value="value"
                    ></el-input>
                </el-form-item>
            </Field>
        </div>
    </div>

        <!-- PORCENTAJE DE PAGO-->
    <RowForm class="paddingTop" :twoColums="true" v-if="!isSupplier">
        <template v-slot:default>
            <label class="required fs-6 fw-bold mb-2">Porcentaje de Pago</label>
            <Field  name="Porcentage" v-slot="{value = 1, field, errorMessage}">
                <el-form-item :error="errorMessage">
                    <el-input-number 
                        class="itemControl"
                        v-bind="field"
                        :min="1" 
                        :max="100"
                        placeholder="Porcentaje de Pago"
                        :validate-event="false"
                        :disabled="readOnly"
                        :model-value="value"/>
                </el-form-item>
            </Field>
        </template>
    </RowForm>
    
</ModalForm>
</template>

<script>
import { defineComponent, onBeforeMount, ref, toRefs, computed } from 'vue';
import { Field } from "vee-validate";
import * as Validations from "yup";
import ModalForm from "../../components/c-modal-form/ModalFormMaster.vue";
import SelectOption from "../../components/c-form-elements/SelectOption.vue";
import ApiService from "@/core/services/ApiService";
import RowForm from "../../components/c-form-elements/RowForm.vue";
import SwalMessageService from "@/core/services/SwalMessageService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';

export default defineComponent({
    components:{
        Field,
        ModalForm,
        SelectOption,
        RowForm
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: "N"
        },
        titleModal: {
            type: String,
            required: false,
            default: "Nuevo Artículo"
        },
        currentOpenedItem: {
            type: Object,
            required: false,
            default: () => null
        },
        fnCallBackPersistenceNotifier: {
            type: Function,
            required: false
        },
    },
    setup(props) {
        const { mode, currentOpenedItem } = toRefs(props);
        const messageService = new SwalMessageService();
        const editForm = ref(null);
        const modalForm = ref();
        const PersonRoleType = [
            { id: 0, name: "Administrador" },
            { id: 1, name: "Persona" },
            { id: 2, name: "Arrendador" },
            { id: 3, name: "Tripulación" },
            { id: 4, name: "Patrón" },
            { id: 5, name: "Proveedor" }
        ];
        let txtName = ref("");
        let controller = "suppliers";
        let supplierType = ref(0);
        const validationSchemaNotSupplier = Validations.object().shape({
            Code: Validations.string().label("Code"),
            Name: Validations.string().required().label("Name"),
            RFC: Validations.string().label("RFC"),
            Email: Validations.string().email("El correo no tiene un formato válido").label("Email"),
            Porcentage: Validations.number().default(1).typeError("El porcentaje debe ser un valor numérico").min(1, "El valor mínimo es 1").max(100, "El valor máximo es 100").required().label("Porcentage"),
            PersonRoleType: Validations.number().required().label("PersonRoleType"),
            PhoneNumber: Validations.string().min(10, "La longitud del teléfono debe ser de 10 dígitos").max(10, "La longitud del teléfono debe ser de 10 dígitos").label("PhoneNumber")
        });

        const validationSchemaSupplier = Validations.object().shape({
            Code: Validations.string().label("Code"),
            Name: Validations.string().required().label("Name"),
            RFC: Validations.string().label("RFC"),
            Email: Validations.string().email("El correo no tiene un formato válido").label("Email"),
            PersonRoleType: Validations.number().required().label("PersonRoleType"),
            PhoneNumber: Validations.string().min(10, "La longitud del teléfono debe ser de 10 dígitos").max(10, "La longitud del teléfono debe ser de 10 dígitos").label("PhoneNumber")
        });

        //HOOKS
        onBeforeMount(() => {
            supplierType.value = 0;
        });

        //VARAIBLES COMPUTADAS
        const validationSchema = computed(() => {
            if(supplierType.value == 5) {
                return validationSchemaSupplier;
            } else {
                return validationSchemaNotSupplier;
            }
        });

        const initialValues = computed(() => {
            let item = {};
            if((mode.value === "M" || mode.value === "V") && currentOpenedItem.value) {
                item.Id = currentOpenedItem.value.id;
                item.Code = currentOpenedItem.value.code;
                item.Name = currentOpenedItem.value.name;
                item.RFC = currentOpenedItem.value.rfc;
                item.Email = currentOpenedItem.value.email;
                item.PersonRoleType = currentOpenedItem.value.personRoleType;
                item.PhoneNumber = currentOpenedItem.value.phoneNumber;
                item.Porcentage = currentOpenedItem.value.personRoleType < 5 ? currentOpenedItem.value.porcentage : 0;
            }
            return item;
        });

        const disableSubmitButton = computed(() => {
            return mode.value === "V";
        });

        const readOnly = computed(() => {
            return mode.value === "V";
        });

        const isEditing = computed(() => {
            return mode.value === "M" || mode.value === "V";
        });

        const showCodeField = computed(() => {
            return mode.value != "N";
        });

        const isSupplier = computed(() => {
            return supplierType.value == 5;
        });

        //FUNCIONES
        const initDefaults = () => {
            modalForm.value.$refs.form.setFieldValue("code", "");
            modalForm.value.$refs.form.setFieldValue("name", "");
            modalForm.value.$refs.form.setFieldValue("RFC", "");
            modalForm.value.$refs.form.setFieldValue("Email", "");
            modalForm.value.$refs.form.setFieldValue("Porcentage", 0);
            modalForm.value.$refs.form.setFieldValue("PersonRoleType", 0);
            modalForm.value.$refs.form.setFieldValue("PhoneNumber", 0);
            modalForm.value.$refs.form.resetForm(); 
        }

        const handleCrudAction = async(values) => {
            if(props.mode === "N") {
                await saveNewItem(values);
            } else if(props.mode === "M") {
                saveUpdatedItem(values);
            }
        }

        const saveNewItem = async(item) => {
            ApiService.post(controller, item).then(res => {
                if(res.data.id) {
                    item.id = res.data.id;
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    if(props.fnCallBackPersistenceNotifier) {
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const saveUpdatedItem = async(item) => {
            let url = `${controller}/${currentOpenedItem.value.id}`;
            item.Id = currentOpenedItem.value.id;
            await ApiService.put(url, item).then(res => {
                if(res.status == 204) {
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    if(props.fnCallBackPersistenceNotifier) {
                        let objUpd = {
                            Id: item.Id,
                            name: item.Name,
                            rfc: item.RFC,
                            email: item.Email,
                            phoneNumber: item.PhoneNumber,
                            personRoleTypeName: PersonRoleType.find(p => p.id == item.PersonRoleType).name
                        };
                        props.fnCallBackPersistenceNotifier(objUpd);
                    }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const onChangeRoleType = async(type) => {
            supplierType.value = type;
        }

        return {
            editForm,
            modalForm,
            txtName,
            PersonRoleType,

            //Variables computadas
            validationSchema,
            initialValues,
            readOnly,
            disableSubmitButton,
            isEditing,
            showCodeField,
            isSupplier,

            //Funciones
            handleCrudAction,
            onChangeRoleType,
            initDefaults
        }
    },
})
</script>

<style lang="scss">

    .itemControl {
        width: 250px;
    }

    .rowDivControl {
        display: flex;
        justify-content: space-between;
    }

    .itemControlMiddle {
        width: 250px;
    }

    .itemDivControl {
        display: flex;
        flex-direction: column;
    }

    .padd {
        padding-left: 10px;
    }

    .paddingTop {
        padding-top: 15px;
    }

    .inlineRows {
        display: flex;
    }

</style>
